.App {
  text-align: center;
}
body {
  background: #a9fbff !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  background-position: center center !important;
}
.gif__container {
  width: 100%;
}
.gif {
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  height: auto;
  margin-bottom: 3vh;
  margin-top: 8vh;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.651) 0px 15px 25px;
}
.logo__image {
  width: 100%;
  /* margin-left: 15%;
  margin-right: 15%; */
  height: auto;
  margin-bottom: 3vh;
  margin-top: 2vh;
}
